import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {UserAccountManagementService} from '../user-account-management/user-account-management.service';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable()
export class AuthenticationGuard  {

	constructor(private userManagementService: UserAccountManagementService, private router: Router) {
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean |
		UrlTree> | boolean | UrlTree {
		if (this.userManagementService.isUserAuthenticated()) {
			return true;
		} else {
			this.userManagementService.clearUserData();
			this.router.navigate(['login']);
			return false;
		}
	}
}
